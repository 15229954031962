import { StringFormat } from "@redotech/util/string-format";

export type Json =
  | JsonArray
  | JsonBoolean
  | JsonObject
  | JsonNumber
  | JsonNull
  | JsonString;

export interface JsonArray extends Array<Json> {}

export type JsonBoolean = boolean;

export interface JsonObject {
  [key: JsonString]: Json;
}

export type JsonNumber = number;

export type JsonNull = null;

export type JsonString = string;

export const jsonStringFormat: StringFormat<Json> = {
  read(string: string) {
    return JSON.parse(string);
  },
  write(value: Json) {
    return JSON.stringify(value);
  },
};

/**
 * Result of JSON.parse(JSON.stringify(t))
 */
export type Jsonified<T> = T extends { toJSON(): infer R }
  ? R
  : T extends string | number | boolean | null
    ? T
    : T extends (infer E)[]
      ? Jsonified<E>[]
      : T extends Function
        ? never
        : T extends object
          ? { [K in keyof T]: Jsonified<T[K]> }
          : never;
