// extracted by mini-css-extract-plugin
export var bgImage = "_f-v";
export var body = "_f-u";
export var buttonBar = "_f-5";
export var card = "_f-0";
export var center = "_f-z";
export var closeButton = "_f-11";
export var collapseHeader = "_f-6";
export var collapseIcon = "_f-c";
export var collapseSection = "_f-a";
export var collapseTitle = "_f-9";
export var collapseTrigger = "_f-7";
export var collapsed = "_f-b";
export var dark = "_f-1";
export var grid = "_f-4";
export var header = "_f-e";
export var headerContainer = "_f-d";
export var headerSubtitle = "_f-i";
export var headerTitle = "_f-f";
export var inherit = "_f-8";
export var large = "_f-g";
export var left = "_f-10";
export var medium = "_f-h";
export var noPadding = "_f-3";
export var outlined = "_f-n";
export var scrollable = "_f-2";
export var section = "_f-m";
export var sectionHeader = "_f-q";
export var selected = "_f-o";
export var separator = "_f-p";
export var subsection = "_f-r";
export var subtitle = "_f-s";
export var subtotalCollapseHeader = "_f-w";
export var subtotalCollapseHeaderTitle = "_f-x";
export var subtotalItem = "_f-y";
export var table = "_f-j";
export var tableCell = "_f-l";
export var tableHeader = "_f-k";
export var title = "_f-t";