// extracted by mini-css-extract-plugin
export var alignBaseline = "_d-8";
export var alignCenter = "_d-4";
export var alignFlexEnd = "_d-6";
export var alignFlexStart = "_d-5";
export var alignSelfBaseline = "_d-d";
export var alignSelfCenter = "_d-9";
export var alignSelfFlexEnd = "_d-b";
export var alignSelfFlexStart = "_d-a";
export var alignSelfStretch = "_d-c";
export var alignStretch = "_d-7";
export var column = "_d-3";
export var flex = "_d-0";
export var justifyCenter = "_d-e";
export var justifyFlexEnd = "_d-g";
export var justifyFlexStart = "_d-f";
export var justifySpaceAround = "_d-i";
export var justifySpaceBetween = "_d-h";
export var justifySpaceEvenly = "_d-j";
export var reverse = "_d-2";
export var row = "_d-1";
export var wrapNowrap = "_d-l";
export var wrapWrap = "_d-k";
export var wrapWrapReverse = "_d-m";