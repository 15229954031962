// extracted by mini-css-extract-plugin
export var brand = "_a-9";
export var button = "_a-0";
export var chevron = "_a-u";
export var chevronButton = "_a-v";
export var content = "_a-n";
export var danger = "_a-g";
export var dangerOutlined = "_a-h";
export var dark = "_a-l";
export var darkTheme = "_a-c";
export var extraSmall = "_a-4";
export var fullWidth = "_a-8";
export var ghost = "_a-d";
export var gray = "_a-s";
export var icon = "_a-o";
export var iconButton = "_a-r";
export var iconButtonBorder = "_a-w";
export var iconButtonBorderDark = "_a-x";
export var iconButtonBorderLight = "_a-y";
export var iconLeft = "_a-p";
export var iconRight = "_a-q";
export var large = "_a-1";
export var light = "_a-m";
export var medium = "_a-2";
export var micro = "_a-5";
export var nano = "_a-7";
export var outlined = "_a-k";
export var pending = "_a-a";
export var primary = "_a-i";
export var small = "_a-3";
export var solidLight = "_a-e";
export var solidLightBrand = "_a-f";
export var spinner = "_a-t";
export var transparent = "_a-j";
export var warning = "_a-b";
export var wideNano = "_a-6";