import { Card } from "@redotech/redo-web/card";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormRadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import { memo } from "react";

export const returnProcessingForm = groupInput({
  shopAgain: input<
    "gift_card" | "discount_code" | "rise" | "shopify_account"
  >(),
  riseTriggerUrl: input<string>(),
  portalExcludedProductTags: input({ equal: arrayEqual(stringEqual) }),
  allowReopen: input<boolean>(),
  hideGiftCardBranding: input<boolean>(),
});

export type ReturnProcessingForm = InputProvider.Form<
  typeof returnProcessingForm
>;

export type ReturnProcessingValue = InputProvider.Value<
  typeof returnProcessingForm
>;

export const returnProcessingDefault: ReturnProcessingValue = {
  riseTriggerUrl: "",
  shopAgain: "gift_card",
  portalExcludedProductTags: [],
  allowReopen: false,
  hideGiftCardBranding: false,
};

export const ReturnProcessingCard = memo(function ReturnProcessingCard({
  input,
}: {
  input: ReturnProcessingForm;
}) {
  const {
    shopAgain,
    riseTriggerUrl,
    portalExcludedProductTags,
    allowReopen,
    hideGiftCardBranding,
  } = input.inputs;
  return (
    <Card title="Return processing">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <FormRadioGroup
            input={shopAgain}
            label="Store credit type"
            layout={RadioGroupLayout.HORIZONTAL}
            optionLabel={(option) =>
              ({
                gift_card: "Gift card",
                discount_code: "Discount code",
                rise: "Rise credit",
                shopify_account: "Shopify account",
              })[option]
            }
            options={["gift_card", "discount_code", "rise", "shopify_account"]}
          >
            Type of store credit to issue
          </FormRadioGroup>
        </div>
        {shopAgain.value === "rise" && (
          <div className={gridCss.span12}>
            <FormTextInput
              description="Rise trigger URL"
              input={riseTriggerUrl}
              label="URL of Rise custom trigger"
            />
          </div>
        )}
        <div className={gridCss.span12}>
          <FormChipInput
            delimiter={ChipDelimiter.NEWLINE}
            description="Product tags to exclude from the customer portal"
            input={portalExcludedProductTags}
            label="Excluded product tags"
            trimWhitespace
          />
        </div>
        <div className={gridCss.span12}>
          <FormSwitch
            input={allowReopen}
            label="Allow reopening returns and claims after processing"
          >
            This feature should be enabled in very few circumstances. Do not
            enabled without good reason.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormSwitch
            input={hideGiftCardBranding}
            label="Hide Redo gift card branding"
          >
            changes the first four digits of a gift card/code from REDO to four
            random characters
          </FormSwitch>
        </div>
      </section>
    </Card>
  );
});
