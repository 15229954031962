// extracted by mini-css-extract-plugin
export var actions = "_b-0";
export var actionsContainer = "_b-3";
export var border = "_b-1";
export var collapse = "_b-2";
export var compact = "_b-b";
export var content = "_b-4";
export var contentChild = "_b-8";
export var fullscreen = "_b-6";
export var header = "_b-9";
export var headerContainer = "_b-c";
export var hidePadding = "_b-5";
export var noScrollX = "_b-d";
export var noScrollY = "_b-e";
export var optOutTopPadding = "_b-7";
export var profile = "_b-f";
export var profileContainer = "_b-g";
export var profileImage = "_b-h";
export var profileMain = "_b-i";
export var profileSubtitle = "_b-k";
export var profileTitle = "_b-j";
export var scrolledTop = "_b-a";
export var title = "_b-l";