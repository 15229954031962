import { ExchangesSelection } from "@redotech/redo-model/team";
import { Card } from "@redotech/redo-web/card";
import {
  ColorPickerBasic,
  Direction,
} from "@redotech/redo-web/color-picker-basic";
import { FormFontSelect } from "@redotech/redo-web/font-select";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import * as labeledInputCss from "@redotech/redo-web/labeled-input.module.css";
import { FormRadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";

export const exchangesForm = groupInput(
  {
    selection: input<ExchangesSelection>(),
    checkoutSelector: input<string>(),
    exchangeBannerColor: input<string>(),
    exchangeBannerText: input<string>(),
    exchangeBannerFont: input<string>(),
    exchangeBannerFontColor: input<string>(),
    exchangeBannerShowDoneButton: input<boolean>(),
  },
  {
    validator: ({ selection, checkoutSelector }) => {
      return selection === ExchangesSelection.STORE_WEBSITE && !checkoutSelector
        ? ["Checkout selector is required for using the store site"]
        : [];
    },
  },
);

export type ExchangesForm = InputProvider.Form<typeof exchangesForm>;

export type ExchangesValue = InputProvider.Value<typeof exchangesForm>;

export const exchangesDefault: ExchangesValue = {
  selection: ExchangesSelection.RETURN_APP,
  checkoutSelector: "",
  exchangeBannerColor: "",
  exchangeBannerText: "",
  exchangeBannerFont: "",
  exchangeBannerFontColor: "",
  exchangeBannerShowDoneButton: true,
};

function selectionLabels(value: ExchangesSelection): string {
  return {
    [ExchangesSelection.RETURN_APP]: "Stay in Redo return portal",
    [ExchangesSelection.STORE_WEBSITE]: "Redirect to store site",
  }[value];
}

export const ExchangesCard = memo(function ExchangesCard({
  input,
  setBannerColor,
  setBannerFontColor,
}: {
  input: ExchangesForm;
  setBannerColor: (color: string) => void;
  setBannerFontColor: (color: string) => void;
}) {
  const {
    selection,
    checkoutSelector,
    exchangeBannerColor,
    exchangeBannerText,
    exchangeBannerFont,
    exchangeBannerFontColor,
    exchangeBannerShowDoneButton,
  } = input.inputs;

  return (
    <Card title="Exchanges">
      <div className={gridCss.span12}>
        <strong>Advanced exchanges</strong>
      </div>
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
          <FormRadioGroup
            input={selection}
            label="Selection location"
            layout={RadioGroupLayout.VERTICAL}
            optionLabel={selectionLabels}
            options={Object.values(ExchangesSelection)}
          >
            <p>Where to take customers when selecting exchange items.</p>
            {selection.value === ExchangesSelection.STORE_WEBSITE ? (
              <strong>
                This will not work for stores with the "Buy it now" button.
              </strong>
            ) : (
              <br />
            )}
          </FormRadioGroup>
          <br />
          <FormTextInput
            description="Selector of all checkout buttons on the store site (slide-out cart, cart page, etc). You will have to find this manually."
            errorOverride={
              selection.value === ExchangesSelection.STORE_WEBSITE &&
              !checkoutSelector.value
            }
            input={checkoutSelector}
            label="Checkout selector"
            placeholder="Default"
          />
          {selection.value === ExchangesSelection.STORE_WEBSITE &&
            !checkoutSelector.value && (
              <div className={labeledInputCss.error}>
                Checkout selector is required for using store site
              </div>
            )}
        </div>
        {selection.value === ExchangesSelection.STORE_WEBSITE && (
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <LabeledInput label="Banner colors">
              <ColorPickerBasic
                label="Banner background"
                onChange={(color) => setBannerColor(color)}
                openDirection={Direction.BELOW}
                value={exchangeBannerColor.value}
              />
              <ColorPickerBasic
                label="Font color"
                onChange={(color) => setBannerFontColor(color)}
                openDirection={Direction.BELOW}
                value={exchangeBannerFontColor.value}
              />
            </LabeledInput>
            <br />
            <FormFontSelect
              disabled={false}
              input={exchangeBannerFont}
              label="Banner Font Family"
            />
            <br />
            <FormTextInput
              description="Text to display on the banner. Use %value% to display the value of the exchange."
              input={exchangeBannerText}
              label="Banner text"
              placeholder="You have $%value% to spend. When done shopping, select done or click Checkout."
            />
            <br />
            <FormSwitch
              input={exchangeBannerShowDoneButton}
              label="Show done button"
            >
              Whether to show a "Done" button on the banner
            </FormSwitch>
          </div>
        )}
      </section>
    </Card>
  );
});
