import { ClickAwayListener } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, useState } from "react";
import { SketchPicker } from "react-color";
import * as colorPickerCss from "./color-picker.module.css";
import { Flex } from "./flex";

export namespace Direction {
  export const ABOVE = Symbol("above");
  export const BELOW = Symbol("below");
}
export type Direction = typeof Direction.ABOVE | typeof Direction.BELOW;

export const alignments = ["left", "right"] as const;
export type Alignment = (typeof alignments)[number];

export const ColorPickerBasic = memo(function ColorPickerBasic({
  label,
  value,
  onChange,
  openDirection = Direction.ABOVE,
  alignment = "right",
  disabled = false,
}: {
  label?: string;
  value: string;
  onChange: (val: string) => void;
  openDirection?: Direction;
  alignment?: Alignment;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (disabled) {
      setOpen(false);
      return;
    }
    setOpen(!open);
    event.stopPropagation();
  };

  const onClickAway = useHandler(() => {
    setOpen(false);
  });

  const picker = (
    <>
      {open && (
        <ClickAwayListener onClickAway={onClickAway}>
          <div
            className={classNames(colorPickerCss.popover, {
              [colorPickerCss.above]: openDirection === Direction.ABOVE,
              [colorPickerCss.below]: openDirection === Direction.BELOW,
            })}
          >
            <SketchPicker color={value} onChange={(e) => onChange(e.hex)} />
          </div>
        </ClickAwayListener>
      )}
    </>
  );

  const circle = (
    <span
      className={colorPickerCss.colorCircle}
      onClick={handleClick}
      style={{ backgroundColor: value }}
    />
  );

  return label ? (
    <>
      <label>
        <Flex
          align="center"
          cursor={disabled ? "not-allowed" : "pointer"}
          gap="none"
          justify={alignment === "left" ? "flex-start" : "space-between"}
          onClick={handleClick}
          position={label ? undefined : "relative"}
        >
          <span>{label}</span>

          {circle}
        </Flex>
      </label>
      {picker}
    </>
  ) : (
    <>
      {circle}
      {picker}
    </>
  );
});

export const FormColorPickerBasic = memo(function FormColorPickerBasic({
  label,
  input,
  openDirection = Direction.ABOVE,
}: {
  label: string;
  input: Input<string>;
  openDirection?: Direction;
}) {
  return (
    <ColorPickerBasic
      label={label}
      onChange={input.setValue}
      openDirection={openDirection}
      value={input.value}
    />
  );
});
