import { CreateRuleBody } from "@redotech/redo-model/create-rule-body";
import { CreateViewBody } from "@redotech/redo-model/create-view-body";
import { Rule } from "@redotech/redo-model/rule";
import { View } from "@redotech/redo-model/view";
import { filterOptionsV2JsonFormat } from "../../../../../model/src/conversation-filters";
import { RedoAdminClient } from "../../../client";
import { createRule, createView } from "../../../client/team";

export async function setupRules({
  client,
  signal,
  teamId,
  rules,
}: {
  client: RedoAdminClient;
  teamId: string;
  signal?: AbortSignal;
  rules: Rule[];
}) {
  const rulesToCreate: CreateRuleBody[] = rules.map((rule) => {
    return {
      name: rule.name,
      description: rule.description,
      flow: rule.flow,
    };
  });
  await Promise.all(
    rulesToCreate.map((rule) => createRule(client, teamId, rule, signal)),
  );
}

export async function setupViews({
  client,
  signal,
  views,
  teamId,
}: {
  teamId: string;
  views: View[];
  client: RedoAdminClient;
  signal?: AbortSignal;
}) {
  const viewsToCreate: CreateViewBody[] = views.map((view) => {
    return {
      name: view.name,
      filters: filterOptionsV2JsonFormat.write(view.filters),
      isPrivate: false,
    };
  });
  await Promise.all(
    viewsToCreate.map((view) => createView(client, teamId, view, signal)),
  );
}
