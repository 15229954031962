import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { PaymentModel } from "@redotech/redo-model/coverage";
import { CoverageMonitor as CoverageMonitorType } from "@redotech/redo-model/coverage-monitor";
import { Onboarding } from "@redotech/redo-model/onboarding";
import {
  CustomerPortalVersion,
  DynamicReturnTypes,
  ShippingInsurance,
} from "@redotech/redo-model/team";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import {
  pricingRulesConvertTeamToValue,
  pricingRulesConvertValueToTeam,
} from "@redotech/redo-web/settings-elements/dynamic-pricing";
import {
  finalSaleFormValueToTeam,
  finalSaleTeamToFormValue,
} from "@redotech/redo-web/settings-elements/final-sale";
import {
  packageProtectionDefault,
  packageProtectionForm,
  packageProtectionFormValueToTeam,
  packageProtectionTeamToFormValue,
} from "@redotech/redo-web/settings-elements/package-protection-elements";
import { groupInput, InputProvider } from "@redotech/ui/form";
import * as classNames from "classnames";
import * as cloneDeep from "lodash/cloneDeep";
import * as isEqual from "lodash/isEqual";
import * as round from "lodash/round";
import { memo, useContext, useEffect, useLayoutEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../client/context";
import {
  getCoverageMonitoring,
  resetCoverageMonitoring,
} from "../../client/coverage-monitoring";
import { getOnboarding } from "../../client/onboarding";
import { notifySlack, updateCoverage } from "../../client/orders";
import { getTeam, updateTeam } from "../../client/team";
import { PreviewButton } from "../split/preview-button";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { CoverageMonitoringCard } from "./returns-package-protection/coverage-monitor";
import { PackageProtectionCoverageCard } from "./returns-package-protection/package-protection/coverage";
import { PackageProtectionGeneralCard } from "./returns-package-protection/package-protection/general";
import {
  ReturnProcessingCard,
  returnProcessingDefault,
  returnProcessingForm,
} from "./returns-package-protection/return-processing";
import {
  FinalSaleReturnsCard,
  ReturnsCoverageCard,
  returnsCoverageDefault,
  returnsCoverageForm,
  ReturnsPricingEnum,
  Warranties,
  warrantiesDefault,
  warrantiesForm,
} from "./returns-package-protection/returns/coverage";
import {
  ExchangesCard,
  exchangesDefault,
  exchangesForm,
} from "./returns-package-protection/returns/exchanges";
import {
  ReturnsGeneralCard,
  returnsGeneralDefault,
  returnsGeneralForm,
} from "./returns-package-protection/returns/general";
import {
  ShippingCard,
  shippingDefault,
  shippingForm,
} from "./returns-package-protection/shipping";
import {
  StorefrontCard,
  storefrontDefault,
  storefrontForm,
} from "./returns-package-protection/storefront";

type fixedOrPercentageType = "fixed" | "percentage";
export const ReturnsPackageProtectionPage = memo(
  function ReturnsPackageProtectionPage() {
    const client = useRequiredContext(RedoAdminClientContext);

    const params = useParams();
    const teamId = params.teamId!;

    const team = useContext(TeamContext);
    const loadTeam = useRequiredContext(LoadTeamContext);

    const [onboardingLoad, loadOnboarding] = useTriggerLoad((signal) =>
      getOnboarding(client, { teamId, signal }),
    );

    const [coverageMonitoringLoad, loadCoverageMonitoring] = useTriggerLoad(
      (signal) => getCoverageMonitoring(client, { teamId, signal }),
    );

    useLayoutEffect(() => {
      loadTeam();
      loadOnboarding();
      loadCoverageMonitoring();
    }, [teamId]);

    const handleSave = useHandler(() => {
      loadTeam();
      loadOnboarding();
      loadCoverageMonitoring();
    });

    const value: ReturnsPackageProtectionValue | undefined = useMemo(() => {
      if (!team) {
        return;
      }
      const packageProtectionFormValue = packageProtectionTeamToFormValue(team);
      const finalSaleFormValue = finalSaleTeamToFormValue(team);
      return {
        returnProcessing: {
          shopAgain: team.settings.shopAgainAction,
          riseTriggerUrl: team.settings.riseTrigger?.url || "",
          portalExcludedProductTags:
            team.settings.portalExcludedProductTags || [],
          allowReopen:
            team.settings.returns?.allowReopenAfterProcessing ?? false,
          hideGiftCardBranding: !!team.settings.returns?.hideGiftCardBranding,
        },
        returnsGeneral: {
          enabled: team.settings?.returns?.enabled ?? true,
          customerPortalVersion:
            team.settings?.customerPortalVersion || CustomerPortalVersion.V3_0,
          refundAllReturnedItems:
            team.settings?.returns?.refundAllReturnedItems ?? false,
          settlementEnabled: team.settings?.returns?.settlementEnabled ?? false,
          settlementRefund: (
            (team.settings?.returns?.settlementRefund || 0) * 100
          ).toString(),
          allowMultiOrderReturns:
            team.settings.returns?.allowMultiOrderReturns ?? false,
          multiOrderReturnLimit: team.settings.returns?.multiOrderReturnLimit
            ? String(team.settings.returns.multiOrderReturnLimit)
            : "0",
          disableRejectedItems: !!team.settings.returns?.disableRejectedItems,
        },
        returnsCoverage: {
          coverage: {
            credit: team.settings.coverage.storeCredit,
            exchange: team.settings.coverage.exchange,
            refund: team.settings.coverage.refund,
          },
          excludeCoverageIfAnyInvalidProduct:
            team.settings.excludeCoverageIfAnyInvalidProduct ?? false,
          merchantPaidEnabledDates:
            team.settings?.merchantPaidEnabledDates?.map((range) => ({
              start: range.start ? new Date(Date.parse(range.start)) : null,
              end: range.end ? new Date(Date.parse(range.end)) : null,
            })) || [],
          merchantPricePerOrder: team.merchantPricePerOrder || "",
          paymentModel: team.settings.paidModel,
          pricePerOrder: team.pricePerOrder,
          serviceCountries: team.settings.countries,
          returnPricingSelection: team.settings.returns?.dynamicPricing
            ? team.settings.returns.dynamicPricing.type ===
              DynamicReturnTypes.ITEM_COUNT
              ? ReturnsPricingEnum.PER_ITEM
              : ReturnsPricingEnum.ORDER_TOTAL
            : ReturnsPricingEnum.FIXED,
          pricePerAdditonalItem:
            team.settings.returns?.dynamicPricing?.type ===
            DynamicReturnTypes.ITEM_COUNT
              ? String(
                  team.settings.returns?.dynamicPricing?.pricePerAdditonalItem,
                )
              : team.pricePerOrder || "2",
          maxPrice: team.settings.returns?.dynamicPricing?.maxPrice
            ? String(team.settings.returns.dynamicPricing.maxPrice)
            : "5",
          internationalPricing:
            team.settings.returns?.internationalPricing?.map((pricing, i) => {
              return {
                countries: pricing.countries,
                multiplier: pricing.multiplier.toFixed(2),
                id: Symbol(i),
              };
            }) || [],
          finalSaleReturns: finalSaleFormValue,
          dynamicPriceCartValueRuleSet: pricingRulesConvertTeamToValue(
            team.settings.returns?.dynamicPricing?.type ===
              DynamicReturnTypes.CART_VALUE
              ? [team.settings.returns?.dynamicPricing.pricingRuleSet]
              : undefined,
          ),
        },
        exchanges: {
          selection: team.settings.exchanges.selection,
          checkoutSelector: team.settings.checkoutSelector ?? "",
          exchangeBannerColor: team.settings.exchangeBannerColor || "",
          exchangeBannerText: team.settings.exchangeBannerText || "",
          exchangeBannerFont: team.settings.exchangeBannerFont || "",
          exchangeBannerFontColor: team.settings.exchangeBannerFontColor || "",
          exchangeBannerShowDoneButton:
            team.settings.exchangeBannerShowDoneButton ?? true,
        },
        packageProtection: packageProtectionFormValue,
        shipping: {
          carrierOverride: team.settings.shippingCarrierOverride,
          labelAdjustment:
            team.settings.labelPriceAdjustment != null
              ? team.settings.labelPriceAdjustment.toFixed(2)
              : "",
          pickupEnabled: team.settings.pickupEnabled,
          enableOnHoldOrders: !!team.settings.exchanges.enableOnHoldOrders,
          onHoldOrdersOnUpsellOnly:
            !!team.settings.exchanges.onHoldOrdersOnUpsellOnly,
          insured:
            team.settings.shippingInsurance === ShippingInsurance.ENABLED,
          canEditReturnAddress: team.settings.canEditReturnAddress ?? false,
        },
        storefront: {
          attachmentStrategy: team.settings.shopifyExtension?.attachmentStrategy
            ? team.settings.shopifyExtension?.attachmentStrategy
            : team.settings.shopifyExtension?.checkoutExtensionEnabled
              ? "check-out"
              : team.settings.cartToggleEnabled
                ? "single-toggle"
                : "checkbox",
          toggleSubtextEnabled:
            team.settings.shopifyExtension?.toggleSubtextEnabled ?? true,
          BothProductOptions: {
            productTitle:
              team.settings.shopifyExtension?.bothProductNameOverride || "",
            toggleMainText:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.bothProductToggle.titleText || "",
            toggleSubTextPositive:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.bothProductToggle.positiveSubtext || "",
            toggleSubTextNegative:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.bothProductToggle.negativeSubtext || "",
            sku: team.settings.combinedSKU || "",
          },
          cartToggle: {
            useShadowRootInCart: team.settings.useShadowRootInCart || false,
            cartShadowRootSelector: team.settings.cartShadowRootSelector || "",
            selector: team.settings.cartToggleSelector || "",
            placement: team.settings.cartTogglePlacement || "before",
            clickSelector: team.settings.cartClickListenerSelector || "",
            toggleOnColor: team.settings.toggleOnColor || "",
            toggleOffColor: team.settings.toggleOffColor || "",
            toggleCircleColor: team.settings.toggleCircleColor || "",
          },
          checkbox: {
            default: team.settings.redoAutoCheck,
            html: team.settings.html || "",
            selector: team.settings.checkboxSelector || "",
            productName: team.settings.productName || "",
            text: team.settings.checkboxText || "",
            hideCheckboxBranding: team.settings.hideCheckboxBranding || false,
            hideCheckboxPrice: team.settings.hideCheckboxPrice || false,
            textAfterRedo: team.settings.checkboxTextAfterRedo || "",
          },
          checkboxInfoModal: {
            text1: team.settings.modalTextAdjustments?.text1 || "",
            title1: team.settings.modalTextAdjustments?.title1 || "",
            text2: team.settings.modalTextAdjustments?.text2 || "",
            title2: team.settings.modalTextAdjustments?.title2 || "",
            text3: team.settings.modalTextAdjustments?.text3 || "",
            title3: team.settings.modalTextAdjustments?.title3 || "",
            logo: team.settings.modalLogo
              ? new URL(team.settings.modalLogo)
              : undefined,
          },
          hideRedoProduct: {
            enabled: !!team.settings.shopifyExtension?.hideRedoProduct?.enabled,
            productSelector:
              team.settings.shopifyExtension?.hideRedoProduct
                ?.productSelector || "",
            cartCountModifications:
              team.settings.shopifyExtension?.hideRedoProduct?.cartCountModifications?.map(
                (x) => ({ id: Symbol(), ...x }),
              ) || [],
            cartPriceModifications:
              team.settings.shopifyExtension?.hideRedoProduct?.cartPriceModifications?.map(
                (x) => ({ id: Symbol(), ...x }),
              ) || [],
            cartDiscountModifications:
              team.settings.shopifyExtension?.hideRedoProduct?.cartDiscountModifications?.map(
                (x) => ({ id: Symbol(), ...x }),
              ) || [],
          },
          packageProtectionAutoCheck:
            team.settings.packageProtectionAutoCheck ??
            team.settings.redoAutoCheck,
          PackageProtectionProductOptions: {
            productTitle:
              team.settings.shopifyExtension?.packageProtectionNameOverride ||
              "",
            toggleMainText:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.packageProtectionToggle.titleText || "",
            toggleSubTextPositive:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.packageProtectionToggle.positiveSubtext || "",
            toggleSubTextNegative:
              team.settings.shopifyExtension?.toggleTextOptions
                ?.packageProtectionToggle.negativeSubtext || "",
            sku: team.settings.packageProtectionSKU || "",
          },
          returnProductOptions: {
            productTitle:
              team.settings.shopifyExtension?.returnNameOverride || "",
            toggleMainText:
              team.settings.shopifyExtension?.toggleTextOptions?.returnToggle
                .titleText || "",
            toggleSubTextPositive:
              team.settings.shopifyExtension?.toggleTextOptions?.returnToggle
                .positiveSubtext || "",
            toggleSubTextNegative:
              team.settings.shopifyExtension?.toggleTextOptions?.returnToggle
                .negativeSubtext || "",
            sku: team.settings.returnsSKU || "",
          },
          sku2: team.settings.sku2 || "",
          splitProducts:
            team.settings.packageProtection?.splitProducts || false,
          customPdpCss: team.settings.shopifyExtension?.customPdpCss || "",
          customToggleCss:
            team.settings.shopifyExtension?.customToggleCss || "",
          uniqueVariantSKU: team.settings.uniqueVariantSKU ?? false,
          usingCartAndCheckoutToggle:
            team.settings.shopifyExtension?.usingCartAndCheckoutToggle ?? false,
        },
        warranties: {
          enabled: team.settings.warranties?.enabled ?? false,
          warrantyRegistrationCollection:
            team.settings.warranties?.warrantyRegistrationCollection,
          customersCanStartClaims:
            team.settings.warranties?.enabled &&
            team.settings.warranties?.customersCanStartClaims,
          registrationEnabled:
            team.settings.warranties?.enabled &&
            team.settings.warranties?.registrationEnabled,
          flatRateRepairEnabled:
            team.settings.warranties?.flatRateRepairEnabled ?? false,
          flatRateRepairPrice:
            team.settings.warranties?.flatRateRepairPrice ?? "0",
        },
      };
    }, [team]);

    return (
      <ReturnsPackageProtectionForm
        coverageMonitor={coverageMonitoringLoad.value}
        id={teamId}
        key={+!team}
        onboarding={onboardingLoad.value}
        onSave={handleSave}
        value={value}
      />
    );
  },
);

const returnsPackageProtectionForm = groupInput({
  returnProcessing: returnProcessingForm,
  returnsGeneral: returnsGeneralForm,
  returnsCoverage: returnsCoverageForm,
  exchanges: exchangesForm,
  packageProtection: packageProtectionForm,
  shipping: shippingForm,
  storefront: storefrontForm,
  warranties: warrantiesForm,
});

type ReturnsPackageProtectionForm = InputProvider.Form<
  typeof returnsPackageProtectionForm
>;

type ReturnsPackageProtectionValue = InputProvider.Value<
  typeof returnsPackageProtectionForm
>;

const returnsPackageProtectionDefault: ReturnsPackageProtectionValue = {
  returnProcessing: returnProcessingDefault,
  returnsGeneral: returnsGeneralDefault,
  returnsCoverage: returnsCoverageDefault,
  exchanges: exchangesDefault,
  packageProtection: packageProtectionDefault,
  shipping: shippingDefault,
  storefront: storefrontDefault,
  warranties: warrantiesDefault,
};

const ReturnsPackageProtectionForm = memo(
  function ReturnsPackageProtectionForm({
    onSave,
    id,
    onboarding,
    coverageMonitor,
    value,
  }: {
    id: string;
    value: ReturnsPackageProtectionValue | undefined;
    onboarding: Onboarding | undefined;
    coverageMonitor: CoverageMonitorType | undefined;
    onSave?: (value: ReturnsPackageProtectionValue) => void;
  }) {
    const client = useRequiredContext(RedoAdminClientContext);
    const input = useInput(
      returnsPackageProtectionForm,
      value || returnsPackageProtectionDefault,
    );

    useEffect(() => {
      if (!value) {
        return;
      }
      // Overwrite the input value with the value from the server.
      // This is because we're using unique symbols as keys so they'll never be equal.
      if (value.storefront.hideRedoProduct?.cartCountModifications) {
        input.inputs.storefront.inputs.hideRedoProduct.inputs.cartCountModifications.setValue(
          value.storefront.hideRedoProduct.cartCountModifications,
        );
      }
      if (value.storefront.hideRedoProduct?.cartPriceModifications) {
        input.inputs.storefront.inputs.hideRedoProduct.inputs.cartPriceModifications.setValue(
          value.storefront.hideRedoProduct.cartPriceModifications,
        );
      }
      if (value.storefront.hideRedoProduct?.cartDiscountModifications) {
        input.inputs.storefront.inputs.hideRedoProduct.inputs.cartDiscountModifications.setValue(
          value.storefront.hideRedoProduct.cartDiscountModifications,
        );
      }

      if (value.returnsCoverage.internationalPricing) {
        input.inputs.returnsCoverage.inputs.internationalPricing.setValue(
          value.returnsCoverage.internationalPricing,
        );
      }

      if (value.packageProtection.pricingRuleSet) {
        input.inputs.packageProtection.inputs.pricingRuleSet.setValue(
          value.packageProtection.pricingRuleSet,
        );
        if (value.packageProtection.internationalPricingEnabled !== undefined) {
          input.inputs.packageProtection.inputs.internationalPricingEnabled.setValue(
            value.packageProtection.internationalPricingEnabled,
          );
        }
      }
    }, [value]);

    const save: PageFormSave = useHandler(async (signal) => {
      const team = await getTeam(client, { teamId: id, signal });
      const prevMerchantPaidEnabledDates = cloneDeep(
        team.settings.merchantPaidEnabledDates,
      );

      const prevPackagePickupEnabled = cloneDeep(team.settings.pickupEnabled);

      const value = input.value;
      team.settings.canEditReturnAddress = value.shipping.canEditReturnAddress;
      team.settings.checkboxText = value.storefront.checkbox.text || undefined;
      team.settings.html = value.storefront.checkbox.html || undefined;
      team.settings.redoAutoCheck = value.storefront.checkbox.default;
      team.settings.checkboxSelector =
        value.storefront.checkbox.selector || undefined;
      team.settings.checkboxTextAfterRedo =
        value.storefront.checkbox.textAfterRedo || undefined;
      team.settings.hideCheckboxBranding =
        value.storefront.checkbox.hideCheckboxBranding;
      team.settings.hideCheckboxPrice =
        value.storefront.checkbox.hideCheckboxPrice;
      const newMerchantPaidEnabledDates =
        value.returnsCoverage.merchantPaidEnabledDates.map((value) => ({
          start: value.start?.toISOString() || null,
          end: value.end?.toISOString() || null,
        }));
      team.settings.useShadowRootInCart =
        value.storefront.cartToggle.useShadowRootInCart;
      team.settings.cartShadowRootSelector =
        value.storefront.cartToggle.cartShadowRootSelector;
      team.settings.cartToggleEnabled =
        value.storefront.attachmentStrategy === "single-toggle" ||
        value.storefront.attachmentStrategy === "double-toggle" ||
        value.storefront.attachmentStrategy === "cart-card";
      team.settings.cartToggleSelector =
        value.storefront.cartToggle.selector || undefined;
      team.settings.cartTogglePlacement =
        value.storefront.cartToggle.placement || undefined;
      team.settings.cartClickListenerSelector =
        value.storefront.cartToggle.clickSelector || undefined;
      team.settings.toggleOnColor =
        value.storefront.cartToggle.toggleOnColor || "";
      team.settings.toggleOffColor =
        value.storefront.cartToggle.toggleOffColor || "";
      team.settings.toggleCircleColor =
        value.storefront.cartToggle.toggleCircleColor || "";
      team.settings.productName =
        value.storefront.checkbox.productName || undefined;
      team.settings.merchantPaidEnabledDates = newMerchantPaidEnabledDates;
      team.settings.paidModel =
        value?.returnsCoverage.merchantPaidEnabledDates?.some(
          (dateRange) => dateRange.end == null,
        )
          ? PaymentModel.MERCHANT_PAID
          : PaymentModel.CUSTOMER_PAID;
      team.settings.coverage.exchange = value.returnsCoverage.coverage.exchange;
      team.settings.coverage.refund = value.returnsCoverage.coverage.refund;
      team.settings.coverage.storeCredit =
        value.returnsCoverage.coverage.credit;
      team.settings.coverageEnabled =
        (value.returnsCoverage.coverage.exchange ||
          value.returnsCoverage.coverage.refund ||
          value.returnsCoverage.coverage.credit ||
          value.returnsCoverage.finalSaleReturns.enabled) &&
        value.returnsGeneral.enabled;
      team.settings.excludeCoverageIfAnyInvalidProduct =
        value.returnsCoverage.excludeCoverageIfAnyInvalidProduct;
      team.settings.pickupEnabled = value.shipping.pickupEnabled;
      team.settings.shippingCarrierOverride = value.shipping.carrierOverride;
      team.settings.shippingInsurance = value.shipping.insured
        ? ShippingInsurance.ENABLED
        : ShippingInsurance.DISABLED;
      team.settings.labelPriceAdjustment =
        +value.shipping.labelAdjustment || null;
      team.settings.exchanges = {
        ...team.settings.exchanges,
        enableOnHoldOrders: value.shipping.enableOnHoldOrders,
        onHoldOrdersOnUpsellOnly: value.shipping.onHoldOrdersOnUpsellOnly,
      };
      team.settings.shopAgainAction = value.returnProcessing.shopAgain;
      team.settings.riseTrigger = value.returnProcessing.riseTriggerUrl
        ? { url: value.returnProcessing.riseTriggerUrl }
        : null;
      team.settings.portalExcludedProductTags = value.returnProcessing
        .portalExcludedProductTags as string[];
      team.settings.packageProtection = packageProtectionFormValueToTeam(
        value.packageProtection,
        value.storefront.splitProducts,
      );
      team.settings.customerPortalVersion =
        value.returnsGeneral.customerPortalVersion;
      if (!value.returnsGeneral.enabled || !value.packageProtection.enabled) {
        input.inputs.storefront.inputs.splitProducts.setValue(false);
        team.settings.packageProtection.splitProducts = false;
      }
      team.settings.returns = {
        ...team.settings.returns,
        enabled: value.returnsGeneral.enabled,
        refundAllReturnedItems: value.returnsGeneral.refundAllReturnedItems,
        settlementEnabled: value.returnsGeneral.settlementEnabled,
        allowMultiOrderReturns: value.returnsGeneral.allowMultiOrderReturns,
        allowReopenAfterProcessing: value.returnProcessing.allowReopen,
        hideGiftCardBranding: value.returnProcessing.hideGiftCardBranding,
        multiOrderReturnLimit: Number(
          value.returnsGeneral.multiOrderReturnLimit,
        ),
        settlementRefund: Number(value.returnsGeneral.settlementRefund) / 100,
        internationalPricing: value.returnsCoverage.internationalPricing.map(
          (price) => {
            return {
              countries: price.countries,
              multiplier: Math.floor(Number(price.multiplier) * 100) / 100,
            };
          },
        ),
        finalSaleReturns: finalSaleFormValueToTeam(
          value.returnsCoverage.finalSaleReturns,
        ),
        disableRejectedItems: value.returnsGeneral.disableRejectedItems,
      };

      const dynamicPricingRuleSets = pricingRulesConvertValueToTeam(
        value.returnsCoverage.dynamicPriceCartValueRuleSet,
      )?.[0];

      const type: fixedOrPercentageType = "percentage";
      const countries: string[] = [];
      const defaultRuleSet = {
        type,
        countries,
        priceBrackets: [
          {
            value: "2",
            pricePoint: "0",
          },
        ],
      };

      if (
        value.returnsCoverage.returnPricingSelection ===
          ReturnsPricingEnum.PER_ITEM &&
        value.returnsCoverage.pricePerAdditonalItem &&
        value.returnsCoverage.maxPrice
      ) {
        team.settings.returns.dynamicPricing = {
          type: DynamicReturnTypes.ITEM_COUNT,
          pricePerAdditonalItem: Number(
            value.returnsCoverage.pricePerAdditonalItem,
          ),
          maxPrice: Number(value.returnsCoverage.maxPrice),
        };
        // reset other settings to defaults on save
        input.inputs.returnsCoverage.inputs.dynamicPriceCartValueRuleSet.setValue(
          [defaultRuleSet],
        );
      } else if (
        value.returnsCoverage.returnPricingSelection ===
          ReturnsPricingEnum.ORDER_TOTAL &&
        dynamicPricingRuleSets
      ) {
        team.settings.returns.dynamicPricing = {
          type: DynamicReturnTypes.CART_VALUE,
          maxPrice: Number(value.returnsCoverage.maxPrice),
          pricingRuleSet: dynamicPricingRuleSets,
        };
        // reset other settings to defaults on save
        input.inputs.returnsCoverage.inputs.pricePerAdditonalItem.setValue(
          value.returnsCoverage.pricePerOrder || "2",
        );
      } else {
        team.settings.returns.dynamicPricing = undefined;
        // reset other settings to defaults on save
        input.inputs.returnsCoverage.inputs.maxPrice.setValue("5");
        input.inputs.returnsCoverage.inputs.pricePerAdditonalItem.setValue(
          value.returnsCoverage.pricePerOrder || "2",
        );
        input.inputs.returnsCoverage.inputs.dynamicPriceCartValueRuleSet.setValue(
          [defaultRuleSet],
        );
      }

      input.inputs.packageProtection.inputs.percentage.setValue(
        String(round(team.settings.packageProtection.percentage * 100.0, 3)),
      );

      team.settings.countries = value.returnsCoverage.serviceCountries;
      team.settings.countriesPackageProtection =
        value.packageProtection.pricingRuleSet[0].countries ?? [];
      team.merchantPricePerOrder =
        value.returnsCoverage.merchantPricePerOrder || undefined;
      team.pricePerOrder = value.returnsCoverage.pricePerOrder;
      team.settings.returnsSKU =
        value.storefront.returnProductOptions.sku || "";
      team.settings.packageProtectionSKU =
        value.storefront.PackageProtectionProductOptions.sku || "";
      team.settings.combinedSKU = value.storefront.BothProductOptions.sku || "";
      team.settings.uniqueVariantSKU =
        value.storefront.uniqueVariantSKU ?? false;

      team.settings.shopifyExtension = {
        ...team.settings.shopifyExtension,
        customizationVisible:
          team.settings.shopifyExtension?.customizationVisible ?? false,
        customizeCartItem:
          team.settings.shopifyExtension?.customizeCartItem ?? false,
        brandPrefix: team.settings.shopifyExtension?.brandPrefix ?? "",
        bothProductNameOverride:
          value.storefront.BothProductOptions.productTitle ?? "",
        packageProtectionNameOverride:
          value.storefront.PackageProtectionProductOptions.productTitle ?? "",
        returnNameOverride:
          value.storefront.returnProductOptions.productTitle ?? "",
        toggleTextOptions: {
          returnToggle: {
            titleText:
              value.storefront.returnProductOptions.toggleMainText ?? "",
            positiveSubtext:
              value.storefront.returnProductOptions.toggleSubTextPositive ?? "",
            negativeSubtext:
              value.storefront.returnProductOptions.toggleSubTextNegative ?? "",
          },
          packageProtectionToggle: {
            titleText:
              value.storefront.PackageProtectionProductOptions.toggleMainText ??
              "",
            positiveSubtext:
              value.storefront.PackageProtectionProductOptions
                .toggleSubTextPositive ?? "",
            negativeSubtext:
              value.storefront.PackageProtectionProductOptions
                .toggleSubTextNegative ?? "",
          },
          bothProductToggle: {
            titleText: value.storefront.BothProductOptions.toggleMainText ?? "",
            positiveSubtext:
              value.storefront.BothProductOptions.toggleSubTextPositive ?? "",
            negativeSubtext:
              value.storefront.BothProductOptions.toggleSubTextNegative ?? "",
          },
        },
        attachmentStrategy: value.storefront.attachmentStrategy,
        toggleSubtextEnabled: value.storefront.toggleSubtextEnabled,
        hideRedoProduct: {
          enabled: value.storefront.hideRedoProduct.enabled,
          productSelector: value.storefront.hideRedoProduct.productSelector,
          cartCountModifications:
            value.storefront.hideRedoProduct.cartCountModifications.map(
              (x) => ({
                selector: x.selector,
                search: x.search,
                replace: x.replace,
              }),
            ),
          cartPriceModifications:
            value.storefront.hideRedoProduct.cartPriceModifications.map(
              (x) => ({
                selector: x.selector,
                search: x.search,
                replace: x.replace,
              }),
            ),
          cartDiscountModifications:
            value.storefront.hideRedoProduct.cartDiscountModifications.map(
              (x) => ({
                selector: x.selector,
                search: x.search,
                replace: x.replace,
              }),
            ),
        },
        checkoutExtensionEnabled:
          value.storefront.attachmentStrategy === "check-out" ||
          value.storefront.attachmentStrategy === "double-check-out",

        customPdpCss: value.storefront.customPdpCss,
        customToggleCss: value.storefront.customToggleCss,
        usingCartAndCheckoutToggle: value.storefront.usingCartAndCheckoutToggle,
      };
      team.settings.packageProtectionAutoCheck =
        value.storefront.packageProtectionAutoCheck;
      team.settings.modalTextAdjustments = {
        text1: value.storefront.checkboxInfoModal.text1,
        title1: value.storefront.checkboxInfoModal.title1,
        text2: value.storefront.checkboxInfoModal.text2,
        title2: value.storefront.checkboxInfoModal.title2,
        text3: value.storefront.checkboxInfoModal.text3,
        title3: value.storefront.checkboxInfoModal.title3,
      };
      team.settings.modalLogo = value.storefront.checkboxInfoModal.logo
        ? value.storefront.checkboxInfoModal.logo.toString()
        : "";

      team.settings.exchanges.selection = value.exchanges.selection;
      team.settings.checkoutSelector =
        value.exchanges.checkoutSelector === ""
          ? undefined
          : value.exchanges.checkoutSelector;
      team.settings.exchangeBannerColor = value.exchanges.exchangeBannerColor;
      team.settings.exchangeBannerText = value.exchanges.exchangeBannerText;
      team.settings.exchangeBannerFont = value.exchanges.exchangeBannerFont;
      team.settings.exchangeBannerFontColor =
        value.exchanges.exchangeBannerFontColor;
      team.settings.exchangeBannerShowDoneButton =
        value.exchanges.exchangeBannerShowDoneButton;

      team.settings.warranties = {
        enabled: value.warranties.enabled,
        warrantyRegistrationCollection:
          value.warranties.warrantyRegistrationCollection,
        customersCanStartClaims:
          value.warranties.enabled && value.warranties.customersCanStartClaims,
        registrationEnabled:
          value.warranties.enabled && value.warranties.registrationEnabled,
        flatRateRepairEnabled: value.warranties.flatRateRepairEnabled,
        flatRateRepairPrice: value.warranties.flatRateRepairPrice,
      };

      await updateTeam(client, { teamId: id, team, signal });
      if (!isEqual(prevMerchantPaidEnabledDates, newMerchantPaidEnabledDates)) {
        // Update coverage on orders if merchantPaidEnabledDates changed
        await updateCoverage(client, { teamId: id, signal });
      }
      if (prevPackagePickupEnabled && !value.shipping.pickupEnabled) {
        await notifySlack(client, { teamId: id, teamName: team.name, signal });
      }

      onSave && onSave(value);
    });

    const setBannerColor = useHandler((color: string) => {
      input.inputs.exchanges.inputs.exchangeBannerColor.setValue(color);
    });

    const setBannerFontColor = useHandler((color: string) => {
      input.inputs.exchanges.inputs.exchangeBannerFontColor.setValue(color);
    });

    const setToggleOnColor = useHandler((color: string) => {
      input.inputs.storefront.inputs.cartToggle.inputs.toggleOnColor.setValue(
        color,
      );
    });

    const setToggleOffColor = useHandler((color: string) => {
      input.inputs.storefront.inputs.cartToggle.inputs.toggleOffColor.setValue(
        color,
      );
    });

    const setToggleCircleColor = useHandler((color: string) => {
      input.inputs.storefront.inputs.cartToggle.inputs.toggleCircleColor.setValue(
        color,
      );
    });

    const {
      returnProcessing,
      returnsGeneral,
      returnsCoverage,
      exchanges,
      packageProtection,
      shipping,
      storefront,
      warranties,
    } = input.inputs;

    const getTreatment = useHandler(async () => {
      const currentTeam = await getTeam(client, { teamId: id });
      const value = input.value;
      return {
        name: "temp",
        weight: 1,
        active: false,
        settings: {
          redoEnabled: true,
          returnEnabled: value.returnsGeneral.enabled,
          packageProtectionEnabled: value.packageProtection.enabled,
          attachmentStrategy: value.storefront.attachmentStrategy,
          toggleTextOptions: {
            returnToggle: {
              titleText: value.storefront.returnProductOptions.toggleMainText,
              positiveSubtext:
                value.storefront.returnProductOptions.toggleSubTextPositive,
              negativeSubtext:
                value.storefront.returnProductOptions.toggleSubTextNegative,
            },
            packageProtectionToggle: {
              titleText:
                value.storefront.PackageProtectionProductOptions.toggleMainText,
              positiveSubtext:
                value.storefront.PackageProtectionProductOptions
                  .toggleSubTextPositive,
              negativeSubtext:
                value.storefront.PackageProtectionProductOptions
                  .toggleSubTextNegative,
            },
            bothProductToggle: {
              titleText: value.storefront.BothProductOptions.toggleMainText,
              positiveSubtext:
                value.storefront.BothProductOptions.toggleSubTextPositive,
              negativeSubtext:
                value.storefront.BothProductOptions.toggleSubTextNegative,
            },
          },
          hideBranding: currentTeam.settings.hidePortalBranding,
          cartElementPlacementSelector: value.storefront.cartToggle.selector,
          cartListenerSelector: value.storefront.cartToggle.clickSelector,
          cartElementPlacement: value.storefront.cartToggle.placement,
          hideCartProduct: value.storefront.hideRedoProduct.enabled,
          cartElementImage: undefined, //@Samuel TODO this should be a field on the admin dash
          redoAutoEnable: value.storefront.checkbox.default,
          packageProtectionAutoEnable:
            value.storefront.packageProtectionAutoCheck,
          hideOnPDP: true, //@Samuel TODO this should be a field on the admin dash
          usingCartAndCheckoutToggle: undefined, //@Samuel TODO this should be a field on the admin dash
          customToggleCss: value.storefront.customToggleCss,
        },
      };
    });

    return (
      <div className={teamCss.container}>
        <PageForm
          additionalActions={
            <PreviewButton getTreatment={getTreatment} teamId={id} />
          }
          initial={value || returnsPackageProtectionDefault}
          input={input}
          save={save}
        >
          <div className={gridCss.grid}>
            <div className={classNames(gridCss.span12, gridCss.span6L)}>
              <div className={gridCss.grid}>
                <div className={gridCss.span12}>
                  <ReturnsGeneralCard input={returnsGeneral} />
                </div>
                {returnsGeneral.value.enabled && (
                  <div className={gridCss.span12}>
                    <ReturnsCoverageCard
                      input={returnsCoverage}
                      onboarding={onboarding}
                    />
                  </div>
                )}
                {returnsGeneral.value.enabled && (
                  <div className={gridCss.span12}>
                    <ExchangesCard
                      input={exchanges}
                      setBannerColor={setBannerColor}
                      setBannerFontColor={setBannerFontColor}
                    />
                  </div>
                )}
                <div className={gridCss.span12}>
                  <PackageProtectionGeneralCard input={packageProtection} />
                </div>
                {packageProtection.value.enabled && (
                  <div className={gridCss.span12}>
                    <PackageProtectionCoverageCard input={packageProtection} />
                  </div>
                )}
                <div className={gridCss.span12}>
                  <ReturnProcessingCard input={returnProcessing} />
                </div>
                <div className={gridCss.span12}>
                  <ShippingCard
                    input={shipping}
                    labelAdjustmentDefault={
                      input.value.returnsCoverage.merchantPricePerOrder ||
                      input.value.returnsCoverage.pricePerOrder
                    }
                  />
                </div>
                <div className={gridCss.span12}>
                  <Warranties input={warranties} />
                </div>
                <div className={gridCss.span12}>
                  <FinalSaleReturnsCard
                    input={returnsCoverage.inputs.finalSaleReturns}
                  />
                </div>
                <div className={gridCss.span12}>
                  <CoverageMonitoringCard
                    coverageMonitor={coverageMonitor as CoverageMonitorType}
                    onButtonClick={() =>
                      handleCoverageMonitoringReset(id, client)
                    }
                  />
                </div>
              </div>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6L)}>
              <div className={gridCss.grid}>
                <div className={gridCss.span12}>
                  <StorefrontCard
                    input={storefront}
                    packageProtection={packageProtection.value}
                    returnsCoverage={returnsCoverage.value}
                    returnsGeneral={returnsGeneral.value}
                    setToggleCircleColor={setToggleCircleColor}
                    setToggleOffColor={setToggleOffColor}
                    setToggleOnColor={setToggleOnColor}
                  />
                </div>
              </div>
            </div>
          </div>
        </PageForm>
      </div>
    );
  },
);

async function handleCoverageMonitoringReset(teamId: any, client: any) {
  console.log("Sending the resetCoverageMonitoring Request");
  // let newCoverageStatistics =
  await resetCoverageMonitoring(client, { teamId });
  // throw new Error("New Coverage: " + JSON.stringify(newCoverageStatistics));
}
