import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { InputProvider, groupInput } from "@redotech/ui/form";
import classNames from "classnames";
import { memo, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { SupportAiCard, supportAiDefault, supportAiForm } from "./support/ai";
import {
  SupportGeneralCard,
  supportGeneralDefault,
  supportGeneralForm,
} from "./support/general";
import { SupportOnboarding } from "./support/support-onboarding";

export const SupportPage = memo(function SupportPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const value: SupportValue | undefined = useMemo(() => {
    if (!team) {
      return;
    }
    const billingSetting = team.settings?.support?.billing;
    return {
      general: {
        enabled: !!team.settings?.support,
      },
      ai: {
        enabled: !!team.settings?.support?.ai?.enabled,
        aiPricePerMonth: billingSetting?.aiMessageSuggestionPricePerMonth
          ? (billingSetting.aiMessageSuggestionPricePerMonth / 100).toString()
          : "",
        aiOveragePrice: billingSetting?.aiMessageSuggestionOveragePrice
          ? (billingSetting.aiMessageSuggestionOveragePrice / 100).toString()
          : "",
        aiTicketsCovered:
          team.settings.support?.billing?.aiMessageSuggestionTicketsCovered?.toString() ||
          "",
        aiFreeTrialDays:
          billingSetting?.aiMessageSuggestionFreeTrialDays?.toString() || "0",
      },
    };
  }, [team]);

  return (
    <SupportForm
      id={teamId}
      key={+!team}
      onSave={handleSave}
      team={team}
      value={value}
    />
  );
});

const supportForm = groupInput({
  general: supportGeneralForm,
  ai: supportAiForm,
});

export type SupportForm = InputProvider.Form<typeof supportForm>;

export type SupportValue = InputProvider.Value<typeof supportForm>;

const supportDefault: SupportValue = {
  general: supportGeneralDefault,
  ai: supportAiDefault,
};

const SupportForm = memo(function SupportForm({
  onSave,
  id,
  team,
  value,
}: {
  id: string;
  value: SupportValue | undefined;
  team: Team | undefined;
  onSave?: (value: SupportValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input: SupportForm = useInput(supportForm, value || supportDefault);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });

    const value = input.value;
    if (value.general.enabled) {
      team.settings.support = team.settings.support || {
        ticketAssignment: "manual",
        usersForAutoAssignment: [],
        maxTicketsPerUser: "10",
        closeAction: CloseActionMethod.NEXT,
      };
      if (team.settings.support.ai) {
        team.settings.support.ai.enabled = !!value.ai.enabled;
      } else {
        team.settings.support.ai = {
          enabled: !!value.ai.enabled,
          copilotEnabled: false,
          autoGenerateResponses: true,
        };
      }

      let billing = team.settings.support.billing || {};

      if (
        (input.inputs.ai.inputs.aiOveragePrice.changed ||
          input.inputs.ai.inputs.aiPricePerMonth.changed ||
          input.inputs.ai.inputs.aiTicketsCovered.changed) &&
        value.ai.aiOveragePrice &&
        value.ai.aiPricePerMonth &&
        value.ai.aiTicketsCovered
      ) {
        billing = {
          aiMessageSuggestionFreeTrialDays: Number(value.ai.aiFreeTrialDays),
          aiMessageSuggestionPricePerMonth:
            Number(value.ai.aiPricePerMonth) * 100, //store in cents
          aiMessageSuggestionOveragePrice:
            Number(value.ai.aiOveragePrice) * 100,
          aiMessageSuggestionTicketsCovered: Number(value.ai.aiTicketsCovered),
          aiMessageSuggestionBillingConfirmed: false,
          aiMessageSuggestionPlanName: "Custom",
          aiMessageSuggestionFreeTrialUsed: false,
          aiMessageSuggestionAtShopifyCap: false,
          aiMessageSuggestionTicketsUsed: 0,
          aiMessageSuggestionLastDayActive: undefined,
        };
      }

      if (value.ai.aiFreeTrialDays) {
        billing.aiMessageSuggestionFreeTrialDays = Number(
          value.ai.aiFreeTrialDays,
        );
      }
      // check if billing is empty, if so, don't save
      if (Object.keys(billing).length > 0) {
        team.settings.support.billing = billing;
      }
    } else {
      team.settings.support = undefined;
    }
    await updateTeam(client, { teamId: id, team, signal });
    onSave && onSave(value);
  });

  const { general, ai } = input.inputs;

  return (
    <div className={teamCss.container}>
      <PageForm initial={value || supportDefault} input={input} save={save}>
        <div className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportGeneralCard input={general} />
              </div>
              <div className={gridCss.span12}>
                <SupportOnboarding />
              </div>
            </div>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportAiCard input={ai} />
              </div>
            </div>
          </div>
        </div>
      </PageForm>
    </div>
  );
});
