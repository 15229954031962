import { IterableMap } from "@redotech/react-util/component";
import { defaultToggleTextOptions } from "@redotech/redo-model/default-values";
import type {
  AttachmentStrategy,
  ToggleFields,
} from "@redotech/redo-model/team";
import { Button, ButtonBorder, ButtonTheme } from "@redotech/redo-web/button";
import { Card, CollapseSubsection } from "@redotech/redo-web/card";
import {
  ColorPickerBasic,
  Direction,
} from "@redotech/redo-web/color-picker-basic";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormImageUpload } from "@redotech/redo-web/image-upload";
import { LabelTheme, LabeledInput } from "@redotech/redo-web/labeled-input";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { PackageProtectionValue } from "@redotech/redo-web/settings-elements/package-protection-elements";
import { FormSwitch } from "@redotech/redo-web/switch";
import {
  FormTextInput,
  InputLines,
  InputTheme,
} from "@redotech/redo-web/text-input";
import {
  InputProvider,
  groupInput,
  input,
  listInput,
  nonEmptyValidator,
} from "@redotech/ui/form";
import { optionalEqual, urlEqual } from "@redotech/util/equal";
import * as classNames from "classnames";
import { memo } from "react";
import { ClientUploader } from "../../../client/uploader";
import * as teamCss from "../../team.module.css";
import { ReturnsCoverageValue } from "./returns/coverage";
import { ReturnsGeneralValue } from "./returns/general";

const cartCountModificationInput = groupInput({
  id: input<symbol>({ equal: () => true }),
  selector: input<string>({ validator: nonEmptyValidator }),
  search: input<string>({ validator: nonEmptyValidator }),
  replace: input<string>({ validator: nonEmptyValidator }),
});

const cartCountModificationDefault = (
  id: symbol,
): InputProvider.Value<typeof cartCountModificationInput> => ({
  id,
  selector: "",
  search: "\\d+",
  replace: "%count%",
});

const productCustomizations = groupInput({
  productTitle: input<string>(),
  toggleMainText: input<string>(),
  toggleSubTextPositive: input<string>(),
  toggleSubTextNegative: input<string>(),
  sku: input<string>(),
});

type ProductCustomizations = InputProvider.Form<typeof productCustomizations>;

export const storefrontForm = groupInput({
  attachmentStrategy: input<AttachmentStrategy>(),
  toggleSubtextEnabled: input<boolean>(),
  BothProductOptions: productCustomizations,
  cartToggle: groupInput({
    useShadowRootInCart: input<boolean>(),
    cartShadowRootSelector: input<string>(),
    selector: input<string>(),
    placement: input<"before" | "after" | "prepend" | "append">(),
    clickSelector: input<string>(),
    toggleOnColor: input<string>(),
    toggleOffColor: input<string>(),
    toggleCircleColor: input<string>(),
  }),
  checkbox: groupInput({
    default: input<boolean>(),
    selector: input<string>(),
    productName: input<string>(),
    text: input<string>(),
    html: input<string>(),
    hideCheckboxBranding: input<boolean>(),
    hideCheckboxPrice: input<boolean>(),
    textAfterRedo: input<string>(),
  }),
  checkboxInfoModal: groupInput({
    text1: input<string>(),
    title1: input<string>(),
    text2: input<string>(),
    title2: input<string>(),
    text3: input<string>(),
    title3: input<string>(),
    logo: input<URL | undefined>({ equal: optionalEqual(urlEqual) }),
  }),
  hideRedoProduct: groupInput({
    enabled: input<boolean>(),
    productSelector: input<string>(),
    cartCountModifications: listInput(
      () => cartCountModificationInput,
      cartCountModificationDefault,
      (i) => i.id,
    ),
    cartPriceModifications: listInput(
      () => cartCountModificationInput,
      cartCountModificationDefault,
      (i) => i.id,
    ),
    cartDiscountModifications: listInput(
      () => cartCountModificationInput,
      cartCountModificationDefault,
      (i) => i.id,
    ),
  }),
  splitProducts: input<boolean>(),
  uniqueVariantSKU: input<boolean>(),
  packageProtectionAutoCheck: input<boolean>(),
  PackageProtectionProductOptions: productCustomizations,
  sku2: input<string>(),
  returnProductOptions: productCustomizations,
  customPdpCss: input<string>(),
  customToggleCss: input<string>(),
  usingCartAndCheckoutToggle: input<boolean>(),
});

export type StorefrontForm = InputProvider.Form<typeof storefrontForm>;

export type StorefrontValue = InputProvider.Value<typeof storefrontForm>;

export const storefrontDefault: StorefrontValue = {
  attachmentStrategy: "checkbox",
  toggleSubtextEnabled: true,
  sku2: "",
  BothProductOptions: {
    productTitle: "",
    toggleMainText: "",
    toggleSubTextPositive: "",
    toggleSubTextNegative: "",
    sku: "",
  },
  checkbox: {
    default: true,
    selector: "",
    text: "",
    html: "",
    productName: "",
    hideCheckboxBranding: false,
    hideCheckboxPrice: false,
    textAfterRedo: "",
  },
  checkboxInfoModal: {
    text1: "",
    title1: "",
    text2: "",
    title2: "",
    text3: "",
    title3: "",
    logo: undefined,
  },
  cartToggle: {
    useShadowRootInCart: false,
    cartShadowRootSelector: "",
    selector: "",
    placement: "before",
    clickSelector: "",
    toggleOnColor: "",
    toggleOffColor: "",
    toggleCircleColor: "",
  },
  hideRedoProduct: {
    enabled: false,
    productSelector: "",
    cartCountModifications: [],
    cartPriceModifications: [],
    cartDiscountModifications: [],
  },
  packageProtectionAutoCheck: true,
  PackageProtectionProductOptions: {
    productTitle: "",
    toggleMainText: "",
    toggleSubTextPositive: "",
    toggleSubTextNegative: "",
    sku: "",
  },
  returnProductOptions: {
    productTitle: "",
    toggleMainText: "",
    toggleSubTextPositive: "",
    toggleSubTextNegative: "",
    sku: "",
  },
  uniqueVariantSKU: false,
  splitProducts: false,
  customPdpCss: "",
  customToggleCss: "",
  usingCartAndCheckoutToggle: false,
};

export const StorefrontCard = memo(function StorefrontCard({
  input,
  returnsGeneral,
  returnsCoverage,
  packageProtection,
  setToggleOnColor,
  setToggleOffColor,
  setToggleCircleColor,
}: {
  input: StorefrontForm;
  returnsGeneral: ReturnsGeneralValue;
  returnsCoverage: ReturnsCoverageValue;
  packageProtection: PackageProtectionValue;
  setToggleOnColor: (color: string) => void;
  setToggleOffColor: (color: string) => void;
  setToggleCircleColor: (color: string) => void;
}) {
  const {
    cartToggle,
    checkbox,
    hideRedoProduct,
    checkboxInfoModal,
    attachmentStrategy,
    returnProductOptions,
    PackageProtectionProductOptions,
    BothProductOptions,
    packageProtectionAutoCheck,
    splitProducts,
    toggleSubtextEnabled,
    customPdpCss,
    customToggleCss,
    sku2,
    uniqueVariantSKU,
    usingCartAndCheckoutToggle,
  } = input.inputs;

  const {
    useShadowRootInCart: useShadowRootInCart,
    cartShadowRootSelector: cartShadowRootSelector,
    selector: cartToggleSelector,
    placement: cartTogglePlacement,
    clickSelector: cartClickListenerSelector,
    toggleOnColor: toggleOnColor,
    toggleOffColor: toggleOffColor,
    toggleCircleColor: toggleCircleColor,
  } = cartToggle.inputs;

  const {
    default: checkboxDefault,
    text: checkboxText,
    html: checkboxHtml,
    selector: checkboxSelector,
    textAfterRedo: checkboxTextAfterRedo,
    hideCheckboxPrice: hideCheckboxPrice,
    hideCheckboxBranding: hideCheckboxBranding,
    productName: productName,
  } = checkbox.inputs;

  const {
    text1: checkboxInfoModalText1,
    title1: checkboxInfoModalTitle1,
    text2: checkboxInfoModalText2,
    title2: checkboxInfoModalTitle2,
    text3: checkboxInfoModalText3,
    title3: checkboxInfoModalTitle3,
    logo: checkboxInfoModalLogo,
  } = checkboxInfoModal.inputs;

  const {
    enabled: hideProduct,
    productSelector,
    cartCountModifications,
    cartPriceModifications,
    cartDiscountModifications,
  } = hideRedoProduct.inputs;

  const textDefaults = defaultToggleTextOptions({
    exchange: returnsCoverage.coverage.exchange,
    storeCredit: returnsCoverage.coverage.credit,
    refund: returnsCoverage.coverage.refund,
  });

  const packageOn = packageProtection.enabled && packageProtection.coverage;
  const returnOn = returnsGeneral.enabled;

  const skuDefault = sku2.value;

  if (
    (!(packageProtection.enabled && packageProtection.coverage) ||
      !returnsGeneral.enabled ||
      !splitProducts.value) &&
    (attachmentStrategy.value === "double-toggle" ||
      attachmentStrategy.value === "double-check-out")
  ) {
    if (attachmentStrategy.value === "double-toggle") {
      attachmentStrategy.setValue("single-toggle");
    }
    if (attachmentStrategy.value === "double-check-out") {
      attachmentStrategy.setValue("check-out");
    }
  }

  const isToggle =
    attachmentStrategy.value === "single-toggle" ||
    attachmentStrategy.value === "double-toggle" ||
    attachmentStrategy.value === "cart-card";

  const isCheckOut =
    attachmentStrategy.value === "check-out" ||
    attachmentStrategy.value === "double-check-out";

  let dropdownOptions = ["checkbox", "single-toggle", "cart-card", "check-out"];
  const returnPackageAndSplit = packageOn && returnOn && splitProducts.value;
  if (returnPackageAndSplit) {
    dropdownOptions = [
      "checkbox",
      "single-toggle",
      "double-toggle",
      "cart-card",
      "check-out",
      "double-check-out",
    ];
  }

  const showSubtextToggle =
    attachmentStrategy.value === "check-out" ||
    attachmentStrategy.value === "single-toggle";

  const showCartAndCheckoutToggle = attachmentStrategy.value !== "checkbox";

  const showDoubleAutoCheck =
    returnPackageAndSplit &&
    (attachmentStrategy.value === "double-toggle" ||
      attachmentStrategy.value === "double-check-out");

  return (
    <Card title="Storefront">
      <section className={gridCss.grid}>
        {showDoubleAutoCheck && (
          <>
            <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
              <FormSwitch input={checkboxDefault} label="Return Autocheck">
                return toggle on/off by default
              </FormSwitch>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
              <FormSwitch
                input={packageProtectionAutoCheck}
                label="Package Protection Autocheck"
              >
                Package Protection toggle on/off by default
              </FormSwitch>
            </div>
          </>
        )}
        {!showDoubleAutoCheck && (
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <FormSwitch input={checkboxDefault} label="Autocheck">
              Coverage added by default
            </FormSwitch>
          </div>
        )}
        <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
          <FormTextInput
            description="Name of Shopify product for cart, checkout, order summary, etc. Should always be used if PDP text override is filled so that the titles will match."
            input={productName}
            label="Product name"
            placeholder="Default"
          />
        </div>
        <div className={gridCss.span12}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>Product detail page</strong>
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description='Replaces all PDP text except "for $price via Redo"'
            input={checkboxText}
            label="Text override"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description='Will replace the "Free Unlimited" for the PDP text, more dynamic than PDP text override so is preferred above text override'
            input={checkboxHtml}
            label="HTML override"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Selector for PDP placement"
            input={checkboxSelector}
            label="Selector"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Any text that will be placed after 'via Redo' in the PDP text."
            input={checkboxTextAfterRedo}
            label="Text after 'via Redo'"
            placeholder="Default"
          />
        </div>
        <div className={gridCss.span6Xl}>
          <FormSwitch input={hideCheckboxPrice} label="Hide PDP Price">
            Hide the price of coverage {"\u0028"}for $1.98{"\u0029"} in the PDP
            text.
          </FormSwitch>
        </div>
        <div className={gridCss.span6Xl}>
          <FormSwitch input={hideCheckboxBranding} label="Hide PDP Branding">
            Hide the "via Redo" text and small Redo logo on the product detail
            page AND cart toggle.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>Coverage product</strong>
        </div>
        {packageProtection.enabled && returnsGeneral.enabled && (
          <div className={gridCss.span6Xl}>
            <FormSwitch input={splitProducts} label="Split products">
              Create separate package protection and return coverage products
            </FormSwitch>
          </div>
        )}
        <div className={gridCss.span6Xl}>
          <FormSwitch input={uniqueVariantSKU} label="Unique SKUs">
            Create unique SKUs for every product and Variant
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12)}>
          <FormSelectDropdown
            description="Placement in online store"
            input={attachmentStrategy}
            label="Placement"
            options={dropdownOptions}
            placeholder="Default"
          >
            {attachmentStrategyLabel}
          </FormSelectDropdown>
        </div>

        {showSubtextToggle && (
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <FormSwitch
              input={toggleSubtextEnabled}
              label="Add subtext and image"
            >
              Adds image to toggle and subtext that changes according to toggle
              state
            </FormSwitch>
          </div>
        )}

        {showCartAndCheckoutToggle && (
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <FormSwitch
              input={usingCartAndCheckoutToggle}
              label="Toggle in Cart and Checkout"
            >
              Turning this on allows the cart and check out experience to
              co-exist. To work, Placement must be set to a cart experience and
              checkout Ui must be enabled via Shopify
            </FormSwitch>
          </div>
        )}

        {(isToggle || isCheckOut) && (
          <>
            {!splitProducts.value && (
              <>
                {packageProtection.enabled &&
                  packageProtection.coverage &&
                  returnsGeneral.enabled && (
                    <>
                      <div className={gridCss.span12}>
                        <strong>Combined product</strong>
                      </div>
                      <SplitProductCustomizationElement
                        defaultText={textDefaults.bothProductToggle}
                        skuDefault={skuDefault}
                        toggleFields={BothProductOptions}
                      />
                    </>
                  )}

                {(!packageProtection.enabled || !packageProtection.coverage) &&
                  returnsGeneral.enabled && (
                    <>
                      <div className={gridCss.span12}>
                        <strong>Return Product</strong>
                      </div>
                      <SplitProductCustomizationElement
                        defaultText={textDefaults.returnToggle}
                        skuDefault={skuDefault}
                        toggleFields={returnProductOptions}
                      />
                    </>
                  )}

                {packageProtection.enabled &&
                  packageProtection.coverage &&
                  !returnsGeneral.enabled && (
                    <>
                      <div className={gridCss.span12}>
                        <strong>Package Protection Product</strong>
                      </div>
                      <SplitProductCustomizationElement
                        defaultText={textDefaults.packageProtectionToggle}
                        skuDefault={skuDefault}
                        toggleFields={PackageProtectionProductOptions}
                      />
                    </>
                  )}
              </>
            )}

            {splitProducts.value && (
              <>
                <div className={gridCss.span12}>
                  <strong>Return Product</strong>
                </div>
                <SplitProductCustomizationElement
                  defaultText={textDefaults.returnToggle}
                  skuDefault={skuDefault}
                  toggleFields={returnProductOptions}
                />
                <div className={gridCss.span12}>
                  <strong>Package Protection Product</strong>
                </div>
                <SplitProductCustomizationElement
                  defaultText={textDefaults.packageProtectionToggle}
                  skuDefault={skuDefault}
                  toggleFields={PackageProtectionProductOptions}
                />

                <div className={gridCss.span12}>
                  <strong>Combined Product</strong>
                </div>

                <SplitProductCustomizationElement
                  defaultText={textDefaults.bothProductToggle}
                  skuDefault={skuDefault}
                  toggleFields={BothProductOptions}
                />
              </>
            )}
            <div className={gridCss.span12}>
              <strong>Cart toggle</strong>
            </div>
            <div
              className={classNames(
                gridCss.span12,
                gridCss.span6Xl,
                teamCss.monospace,
              )}
            >
              <FormTextInput
                description="CSS Query selector for cart toggle placement. One toggle will be placed for each element matching this selector."
                input={cartToggleSelector}
                label="Selector override"
                placeholder="Default"
              />
            </div>
            <div
              className={classNames(
                gridCss.span12,
                gridCss.span6Xl,
                teamCss.monospace,
              )}
            >
              <FormTextInput
                description="CSS Query selector for click listener, which will trigger a render of the toggles. Does not override the default selector, but adds an additional one."
                input={cartClickListenerSelector}
                label="Click listener selector"
                placeholder="Default"
              />
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
              <FormSelectDropdown
                description={
                  <>
                    <p>Where to place the toggle, relative to the selector:</p>

                    <ul style={{ paddingLeft: "16px" }}>
                      <li>
                        <strong>before</strong>: Before the element, an older
                        sibling.
                      </li>
                      <li>
                        <strong>after</strong>: After the element, a younger
                        sibling.
                      </li>
                      <li>
                        <strong>prepend</strong>: Inside the element, the first
                        child.
                      </li>
                      <li>
                        <strong>append</strong>: Inside the element, the last
                        child.
                      </li>
                    </ul>
                  </>
                }
                input={cartTogglePlacement}
                label="Placement"
                options={["before", "after", "prepend", "append"]}
                placeholder="Default"
              >
                {(s) => s}
              </FormSelectDropdown>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
              <LabeledInput label="Toggle colors">
                <ColorPickerBasic
                  label="On"
                  onChange={(color) => {
                    setToggleOnColor(color);
                  }}
                  openDirection={Direction.BELOW}
                  value={toggleOnColor.value}
                />
                <ColorPickerBasic
                  label="Off"
                  onChange={(color) => {
                    setToggleOffColor(color);
                  }}
                  openDirection={Direction.BELOW}
                  value={toggleOffColor.value}
                />
                <ColorPickerBasic
                  label="Circle"
                  onChange={(color) => {
                    setToggleCircleColor(color);
                  }}
                  openDirection={Direction.BELOW}
                  value={toggleCircleColor.value}
                />
              </LabeledInput>
            </div>
          </>
        )}
        <div className={gridCss.span12}>
          <FormSwitch input={useShadowRootInCart} label="Use shadow root cart">
            Whether the merchant's cart is inside a shadow root
          </FormSwitch>
        </div>
        {useShadowRootInCart.value && (
          <div className={classNames(gridCss.span12, teamCss.monospace)}>
            <FormTextInput
              input={cartShadowRootSelector}
              label="Shadow root host selector"
            />
          </div>
        )}
        <div className={gridCss.span12}>
          <FormSwitch input={hideProduct} label="Hide cart product">
            Whether Redo product is hidden in cart.
            {hideProduct.value && (
              <>
                {" "}
                <strong>
                  Be extremely careful with these settings! Invalid selectors
                  can significantly impact the store.
                </strong>
              </>
            )}
          </FormSwitch>
        </div>
        {hideProduct.value && (
          <>
            <div className={classNames(gridCss.span12, teamCss.monospace)}>
              <FormTextInput
                description="You can use %handle%, %product_id%, and %variant_id% as placeholders."
                input={productSelector}
                label="Selector for Redo product."
              />
            </div>
            <div className={classNames(gridCss.span12)}>
              <CartModificationElement
                cartModifications={cartCountModifications}
                replaceDefault="%count%"
                selectorDescription="Selector containing the cart count"
                title="Cart count modifications"
                variableDescription="String to replace it with. %count% and %line_item_count% are the avaiable template variables."
              />
            </div>

            <div className={classNames(gridCss.span12)}>
              <CartModificationElement
                cartModifications={cartPriceModifications}
                replaceDefault="%cart-subtotal%"
                selectorDescription="Selector containing the cart price"
                title="Cart price modifications"
                variableDescription="Options: %cart-subtotal%, %cart-subtotal|round% and %cart-subtotal|withRedo%"
              />
            </div>

            <div className={classNames(gridCss.span12)}>
              <CartModificationElement
                cartModifications={cartDiscountModifications}
                replaceDefault="%cart-discount%"
                selectorDescription="Selector containing the cart discount"
                title="Cart discount modifications"
                variableDescription="Options: %cart-discount% and %cart-discount|round%"
              />
            </div>
          </>
        )}
        <div className={gridCss.span12}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>Info modal</strong>
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Text body for first section of info modal"
            input={checkboxInfoModalText1}
            label="Text 1"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Title for first section of info modal"
            input={checkboxInfoModalTitle1}
            label="Title 1"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Text body for second section of info modal"
            input={checkboxInfoModalText2}
            label="Text 2"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Title for second section of info modal"
            input={checkboxInfoModalTitle2}
            label="Title 2"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Text body for third section of info modal"
            input={checkboxInfoModalText3}
            label="Text 3"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description="Title for third section of info modal"
            input={checkboxInfoModalTitle3}
            label="Title 3"
            placeholder="Default"
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <ClientUploader>
            <FormImageUpload input={checkboxInfoModalLogo} label="Logo" />
          </ClientUploader>
        </div>
        <div className={gridCss.span12}>
          <CollapseSubsection title="Advanced options">
            <FormTextInput
              input={customPdpCss}
              label="Custom PDP CSS"
              lines={InputLines.MULTI}
              theme={InputTheme.FORM}
            />
            <FormTextInput
              description={`Example:
                  [data-target="target1"] {attribute1: value1 !important;
                  attribute2: value2 !important;}
                  [data-target="target2"] {attribute: value !important;}`}
              input={customToggleCss}
              label="Custom Toggle CSS"
              lines={InputLines.MULTI}
              theme={InputTheme.FORM}
            />
          </CollapseSubsection>
        </div>
      </section>
    </Card>
  );
});

const CartModificationElement = memo(function CartModificationElement({
  title,
  cartModifications,
  variableDescription,
  selectorDescription,
  replaceDefault,
}: {
  title: string;
  cartModifications: any;
  variableDescription: string;
  selectorDescription: string;
  replaceDefault: string;
}) {
  return (
    <LabeledInput label={title}>
      <IterableMap items={cartModifications.inputs} keyFn={(_, i) => i}>
        {(input: any) => {
          const { selector, search, replace } = input.inputs;
          return (
            <div className={gridCss.grid}>
              <div className={classNames(teamCss.monospace, gridCss.span4)}>
                <FormTextInput
                  description={selectorDescription}
                  input={selector}
                  label="Selector"
                  labelTheme={LabelTheme.THIN}
                />
              </div>
              <div className={classNames(teamCss.monospace, gridCss.span4)}>
                <FormTextInput
                  description="Regular expression to match"
                  input={search}
                  label="Search"
                  labelTheme={LabelTheme.THIN}
                />
              </div>
              <div className={classNames(teamCss.monospace, gridCss.span4)}>
                <FormTextInput
                  description={variableDescription}
                  input={replace}
                  label="Replace"
                  labelTheme={LabelTheme.THIN}
                />
              </div>
            </div>
          );
        }}
      </IterableMap>
      <Button
        border={ButtonBorder.LIGHT}
        onClick={() =>
          cartModifications.setValue([
            ...cartModifications.value,
            {
              id: Symbol(),
              selector: "",
              search: "\\d+",
              replace: replaceDefault,
            },
          ])
        }
        theme={ButtonTheme.OUTLINED}
      >
        Add
      </Button>
    </LabeledInput>
  );
});

const SplitProductCustomizationElement = memo(
  function SplitProductCustomizationElement({
    toggleFields,
    defaultText,
    skuDefault,
  }: {
    toggleFields: ProductCustomizations;
    defaultText: ToggleFields;
    skuDefault: string;
  }) {
    const {
      productTitle,
      toggleMainText,
      toggleSubTextPositive,
      toggleSubTextNegative,
      sku,
    } = toggleFields.inputs;
    return (
      <>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput input={productTitle} label="Product title" />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description={`Default: ${defaultText.titleText}. Use %price% as a placeholder for the product price.`}
            input={toggleMainText}
            label="Toggle main text"
            placeholder={defaultText.titleText}
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description={`Default: ${defaultText.positiveSubtext}`}
            input={toggleSubTextPositive}
            label="Subtext when enabled"
            placeholder={defaultText.positiveSubtext}
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            description={`Default: ${defaultText.negativeSubtext}`}
            input={toggleSubTextNegative}
            label="Subtext when disabled"
            placeholder={defaultText.negativeSubtext}
          />
        </div>
        <div
          className={classNames(
            gridCss.span12,
            gridCss.span6Xl,
            teamCss.monospace,
          )}
        >
          <FormTextInput
            input={sku}
            label="SKU"
            placeholder={skuDefault || "x-redo"}
          />
        </div>
        <div className={gridCss.span12}>
          <Divider />
        </div>
      </>
    );
  },
);

function attachmentStrategyLabel(
  attachmentStrategy: AttachmentStrategy,
): string {
  switch (attachmentStrategy) {
    case "checkbox":
      return "PDP checkbox";
    case "single-toggle":
      return "Cart single toggle";
    case "double-toggle":
      return "Cart double toggle";
    case "drop-down":
      return "Cart drop-down";
    case "check-out":
      return "Checkout single checkbox";
    case "double-check-out":
      return "Checkout double checkbox";
    case "cart-card":
      return "Cart card";
  }
}
