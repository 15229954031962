// extracted by mini-css-extract-plugin
export var chip = "_10-a";
export var chipLabel = "_10-b";
export var close = "_10-8";
export var closeIcon = "_10-9";
export var container = "_10-3";
export var error = "_10-7";
export var infoTooltip = "_10-2";
export var innerContainer = "_10-1";
export var input = "_10-c";
export var medium = "_10-6";
export var outerContainer = "_10-0";
export var small = "_10-5";
export var xSmall = "_10-4";