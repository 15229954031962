import { ReturnType } from "../return";
import * as languageResource from "./language";

// Naming: project_element_description
// A word on translation key naming conventions https://tolgee.io/blog/naming-translation-keys
export type Resource = {
  DOCUMENT_TITLE: string;
  TYPE_DISPLAY_NAME: string;
  ORDERLESS_DISPLAY_NAME: string;
  LOGIN_FIELD_ONE_LABEL: string;
  LOGIN_FIELD_TWO_LABEL: string;
  PORTAL_BUTTON_TEXT: string;
  PORTAL_GIFT_BUTTON_TEXT: string;
  PORTAL_POLICY_LINK_TEXT: string;
  ITEMS_OLD_HEADER: string;
  ITEMS_DISCLAIMER: string;
  ITEMS_NEW_HEADER: string;
  THIRD_PARTY_ITEMS_HEADER: string;
  THIRD_PARTY_ITEMS_SUBHEADER: string;
  THIRD_PARTY_DOCUMENT_TITLE: string;
  FEES_HEADER: string;
  FEES_ORDER_HEADER: string;
  REVIEW_SUBMIT_BUTTON_TEXT: string;
  REVIEW_SHIPPING_FROM_TEXT: string;
  REVIEW_LABEL_COVERAGE: string;
  REVIEW_SHIPPING_TO_SAME_TEXT: string;
  REVIEW_FLOW_HEADER: string;
  STATUS_STEPS_TITLE: string;
  STATUS_MANUAL_REVIEW_TITLE: string;
  STATUS_MANUAL_REVIEW_SUBTITLE: string;
  STATUS_IN_TRANSIT_TITLE: string;
  STATUS_IN_TRANSIT_SUBTITLE: string;
  STATUS_IN_TRANSIT_QR_CODE: string;
  STATUS_IN_TRANSIT_MULTI_SHIPMENT_SUBTITLE: string;
  STATUS_PROCESSED_TITLE: string;
  STATUS_PROCESSED_SUBTITLE: string;
  STATUS_SHIPPING_TEXT: string;
  EMAIL_SUBJECT: string;
  EMAIL_HEADING: string;
  EMAIL_BODY: string;
  EMAIL_STEP_1: string;
  IN_STORE_RETURN_EMAIL_STEP_1: string;
  SHOPIFY_NOTE: string;
  REVIEW_HEADER_TEXT: string;
  CHOOSE_ITEMS_TEXT: string;
  PENDING_ITEM_PILL_TEXT: string;
  CONFIRMATION_HEADER_TEXT_SUBMIT: string;
  CONFIRMATION_HEADER_TEXT_SUBMIT_WITH_ERRORS: string;
  CONFIRMATION_HEADER_TEXT_REJECT: string;
  GREEN_RETURN_PROCESS_STEP_TEXT: string;
  PORTAL_ORDERS_TABLE_RETURNS_HEADER: string;
  PORTAL_ORDER_TABLE_RETURN_LINK_TEXT: string;
  ORDER_DETAILS_LIST_TITLE_TEXT: string;
  ORDER_DETAILS_LIST_ITEM_TEXT: string;
  ADDRESS_PICKUP_UNAVAILABLE: string;
};

export type VariantResource<K extends string> = Resource & {
  variants?: {
    [key in K]?: Partial<Resource>;
  };
};

type ReturnMethod = "exchange";

export type Variants = ReturnType | ReturnMethod | "return_claim" | "pickup";

type LanguageResourceImport = typeof languageResource;

// If the imported object is not a VariantResource, than we exclude it from the LanguageResource type
type LanguageResource = {
  [K in keyof LanguageResourceImport as LanguageResourceImport[K] extends VariantResource<Variants>
    ? K
    : never]: LanguageResourceImport[K];
};

type LanguageRegions = keyof LanguageResource;

export type LanguageResourceOverride = {
  [key in LanguageRegions]?: Partial<VariantResource<Variants>>;
};

export const getLanguageRegion = ({
  languageCode,
  countryCode,
}: {
  languageCode?: string;
  countryCode: string;
}): LanguageRegions => {
  const languageRegion =
    `${languageCode || "EN"}_${countryCode}`.toUpperCase() as LanguageRegions;
  return languageRegion in languageResource ? languageRegion : "COMMON";
};

export const getResource = ({
  language,
  variant,
  overrides,
}: {
  language?: LanguageRegions;
  variant?: Variants;
  overrides?: LanguageResourceOverride;
}): Resource => {
  let resource = languageResource["COMMON"];
  if (language) {
    resource = {
      ...resource,
      ...languageResource[language],
    };
  }

  if (variant) {
    resource = {
      ...resource,
      ...resource.variants?.[variant],
    };
  }

  if (overrides) {
    let override = language ? overrides[language] : overrides["COMMON"];
    if (variant && override?.variants) {
      override = {
        ...override,
        ...override?.variants?.[variant],
      };
    }
    resource = {
      ...resource,
      ...override,
    };
  }

  return resource;
};
