import { shippingCarriers } from "@redotech/redo-model/carriers";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormMultiSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import * as classNames from "classnames";
import { memo } from "react";

export const shippingForm = groupInput({
  carrierOverride: input<string[]>({ equal: arrayEqual(stringEqual) }),
  insured: input<boolean>(),
  labelAdjustment: input<string>(),
  pickupEnabled: input<boolean>(),
  enableOnHoldOrders: input<boolean>(),
  onHoldOrdersOnUpsellOnly: input<boolean>(),
  canEditReturnAddress: input<boolean>(),
});

export type ShippingForm = InputProvider.Form<typeof shippingForm>;

export type ShippingValue = InputProvider.Value<typeof shippingForm>;

export const shippingDefault: ShippingValue = {
  carrierOverride: [],
  insured: false,
  labelAdjustment: "",
  pickupEnabled: false,
  enableOnHoldOrders: false,
  onHoldOrdersOnUpsellOnly: false,
  canEditReturnAddress: false,
};

export const ShippingCard = memo(function ShippingCard({
  input,
  labelAdjustmentDefault,
}: {
  input: ShippingForm;
  labelAdjustmentDefault: string;
}) {
  const {
    carrierOverride,
    labelAdjustment,
    pickupEnabled,
    enableOnHoldOrders,
    onHoldOrdersOnUpsellOnly,
    insured,
    canEditReturnAddress,
  } = input.inputs;

  return (
    <Card title="Shipping">
      <div className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormMultiSelectDropdown
            description="Enabled shipping carriers"
            display={(value) => {
              if (!value.length || value.length === shippingCarriers.length) {
                return "All";
              }
              const names = value.map(
                (value) =>
                  shippingCarriers.find((carrier) => carrier.value === value)
                    ?.name,
              );
              return names.join(", ");
            }}
            input={carrierOverride}
            label="Carriers"
            options={shippingCarriers.map((carrier: any) => carrier.value)}
          >
            {(option) =>
              shippingCarriers.find((carrier) => carrier.value === option)?.name
            }
          </FormMultiSelectDropdown>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={insured} label="Shipping insurance">
            Enable shipping insurance. Increases label price by 0.5% of items
            value (min $0.50).
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormTextInput
            description="Adjustment to the label price charged to merchants and customers"
            input={labelAdjustment}
            label="Label adjustment"
            placeholder={labelAdjustmentDefault}
            prefix="$"
          />
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={pickupEnabled} label="Return pickup">
            Offer return package pickup, for the cost of label upgrade plus $2
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={enableOnHoldOrders}
            label="On-hold exchange orders"
          >
            Create exchange orders immediately and place them on hold, which
            reserves inventory but stops them from being fulfilled until the
            return is processed.
          </FormSwitch>
        </div>
        {enableOnHoldOrders.value && (
          <div className={classNames(gridCss.span12, gridCss.span6M)}>
            <FormSwitch
              input={onHoldOrdersOnUpsellOnly}
              label="On-hold for up-sales only"
            >
              will only create an on hold order if the order requires payment
              from the shopper.
            </FormSwitch>
          </div>
        )}
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={canEditReturnAddress}
            label="Change return address"
          >
            Allows Merchant to change return address during return approval
          </FormSwitch>
        </div>
      </div>
    </Card>
  );
});
