import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { memo } from "react";

export const conciergeForm = groupInput({
  enabled: input<boolean>(),
});

export type ConciergeForm = InputProvider.Form<typeof conciergeForm>;

export type ConciergeValue = InputProvider.Value<typeof conciergeForm>;

export const conciergeDefault: ConciergeValue = {
  enabled: false,
};

export const ConciergeCard = memo(function ConciergeCard({
  input,
}: {
  input: ConciergeForm;
}) {
  const { enabled } = input.inputs;

  return (
    <Card title="Concierge">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <div className={gridCss.grid}>
            <div className={gridCss.span6L}>
              <FormSwitch input={enabled} label="Concierge enabled">
                Enable Concierge service
              </FormSwitch>
            </div>
          </div>
        </div>
      </section>
    </Card>
  );
});
