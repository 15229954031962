import CopyIcon from "@redotech/redo-web/arbiter-icon/copy-01.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useContext, useMemo } from "react";
import { REDO_CHAT_WIDGET_URL } from "../../../config";
import { TeamContext } from "../../team";
import * as customerWidgetCss from "./customer-widget-script-tag.module.css";

export const CustomerWidgetScriptTag = memo(function CustomerWidgetScriptTag() {
  const team = useContext(TeamContext);

  const handleCopy = async (code: string) => {
    await navigator.clipboard.writeText(code);
  };

  const code = useMemo(() => {
    if (!team) {
      return "";
    }

    return `
<script async src="${REDO_CHAT_WIDGET_URL}/widget.js?widgetId=${team.widget_slug}"></script>
    `.trim();
  }, [team]);

  return (
    <Card title="Customer Widget script tag">
      <p>
        Use the following code <strong>only</strong> to test/demo the Customer
        Widget. Do not use this during implementation, instead enable the
        Customer Widget from the merchant dashboard settings.
      </p>
      <Flex dir="row" gap="md">
        <div className={customerWidgetCss.codeBlock}>
          <code>{code}</code>
        </div>
        <IconButton onClick={() => handleCopy(code)}>
          <CopyIcon />
        </IconButton>
      </Flex>
    </Card>
  );
});
