// extracted by mini-css-extract-plugin
export var grid = "_4-0";
export var span1 = "_4-2";
export var span10 = "_4-b";
export var span10L = "_4-z";
export var span10M = "_4-n";
export var span10Xl = "_4-1b";
export var span11 = "_4-c";
export var span11L = "_4-10";
export var span11M = "_4-o";
export var span11Xl = "_4-1c";
export var span12 = "_4-d";
export var span12L = "_4-11";
export var span12M = "_4-p";
export var span12Xl = "_4-1d";
export var span1L = "_4-q";
export var span1M = "_4-e";
export var span1Xl = "_4-12";
export var span2 = "_4-3";
export var span2L = "_4-r";
export var span2M = "_4-f";
export var span2Xl = "_4-13";
export var span3 = "_4-4";
export var span3L = "_4-s";
export var span3M = "_4-g";
export var span3Xl = "_4-14";
export var span4 = "_4-5";
export var span4L = "_4-t";
export var span4M = "_4-h";
export var span4Xl = "_4-15";
export var span5 = "_4-6";
export var span5L = "_4-u";
export var span5M = "_4-i";
export var span5Xl = "_4-16";
export var span6 = "_4-7";
export var span6L = "_4-v";
export var span6M = "_4-j";
export var span6Xl = "_4-17";
export var span7 = "_4-8";
export var span7L = "_4-w";
export var span7M = "_4-k";
export var span7Xl = "_4-18";
export var span8 = "_4-9";
export var span8L = "_4-x";
export var span8M = "_4-l";
export var span8Xl = "_4-19";
export var span9 = "_4-a";
export var span9L = "_4-y";
export var span9M = "_4-m";
export var span9Xl = "_4-1a";
export var stretch = "_4-1";