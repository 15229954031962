import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { ExternalLink } from "@redotech/redo-web/link";
import { memo } from "react";
import { RedoAdminClientContext } from "../../client/context";
import {
  createPreviewTreatment as apiCreatePreviewTreatment,
  getTeam,
} from "../../client/team";
import * as splitCss from "./split-tests.module.css";
import { TreatmentRequest } from "./split-utils";

export const PreviewButton = memo(function PreviewButton({
  teamId,
  getTreatment,
}: {
  teamId: string;
  getTreatment: () => Promise<TreatmentRequest>;
}) {
  const client = useRequiredContext(RedoAdminClientContext);

  const handlePreview = useHandler(() =>
    alertOnFailure("Creation of preview treatment failed")(async () => {
      const currentTeam = await getTeam(client, { teamId });
      if (!currentTeam) {
        return false;
      }
      const treatment = await getTreatment();
      const newTreatment = await apiCreatePreviewTreatment(client, {
        treatment,
        teamId,
      });
      const url = `https://${currentTeam.storeUrl}?treatment_id=${newTreatment._id}&preview=true`;
      window.open(url, "_blank");
      return true;
    }),
  );

  return (
    <ExternalLink
      className={splitCss.previewLink}
      onClick={handlePreview}
      showIcon={false}
    >
      Preview
    </ExternalLink>
  );
});
